import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

import Layout from '../components/layout'
import Head from '../components/head'
import contactStyles from './contact.module.scss'

const ContactPage = () => {
  return (
    <div>
      <Layout>
      <Head title="Contact" />
      <h1>Contact</h1>
      <div className={contactStyles.content}>
      <h2 ><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/the-kevin-olson/"><FontAwesomeIcon icon={faLinkedin} className={contactStyles.icons}/></a><a target="_blank" rel="noopener noreferrer" href="https://github.com/KO-11"><FontAwesomeIcon icon={faGithub} className={contactStyles.icons}/></a><a target="_blank" rel="noopener noreferrer" href="mailto:kevin.m.olson11@gmail.com"><FontAwesomeIcon icon={faEnvelope} className={contactStyles.icons}/></a></h2>
      <p>Please reach out anytime</p>
      </div>
      </Layout>
    </div>
  )
}

export default ContactPage